export default {
  paleGrey: {
    100: "#ffffff",
    200: "#ffffff",
    300: "#ffffff",
    400: "#ffffff",
    500: "#ecf9f6",
    600: "#C7EDE5",
    700: "#A6E2D6",
    800: "#88D8C8",
    900: "#6DCFBC",
  },
  deepGreen: {
    100: "#007930",
    200: "#006E2C",
    300: "#006428",
    400: "#005B24",
    500: "#005321",
    600: "#004B1E",
    700: "#00441B",
    800: "#003D18",
    900: "#003716",
  },
  darkIndigo: {
    100: "#0E3A5E",
    200: "#0D3555",
    300: "#0C304D",
    400: "#0B2C46",
    500: "#0a2840",
    600: "#09243A",
    700: "#082034",
    800: "#071D2F",
    900: "#061A2A",
  },
  dullBrown: {
    100: "#B19F77",
    200: "#A79366",
    300: "#9B8759",
    400: "#8D7B51",
    500: "#80704a",
    600: "#736543",
    700: "#675B3C",
    800: "#5D5236",
    900: "#544A31",
  },
}

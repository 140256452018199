exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-activities-page-js": () => import("./../../../src/templates/activities-page.js" /* webpackChunkName: "component---src-templates-activities-page-js" */),
  "component---src-templates-bungalows-page-js": () => import("./../../../src/templates/bungalows-page.js" /* webpackChunkName: "component---src-templates-bungalows-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-environment-page-js": () => import("./../../../src/templates/environment-page.js" /* webpackChunkName: "component---src-templates-environment-page-js" */),
  "component---src-templates-gaials-page-js": () => import("./../../../src/templates/gaials-page.js" /* webpackChunkName: "component---src-templates-gaials-page-js" */),
  "component---src-templates-general-page-js": () => import("./../../../src/templates/general-page.js" /* webpackChunkName: "component---src-templates-general-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-plots-page-js": () => import("./../../../src/templates/plots-page.js" /* webpackChunkName: "component---src-templates-plots-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}


export default {
  baseStyle: {
    maxW: "1200px",
  },
  variants: {
    "with-vertical-padding": {
      py: "1rem",
    },
  },
}

module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"isUsingColorMode":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Càmping La Mola","short_name":"La Mola","description":"Càmping La Mola","lang":"ca","start_url":"/","background_color":"#fff","theme_color":"#00ab59","display":"minimal-ui","icon":"static/images/icon.svg","icon_options":{"purpose":"any maskable"},"localize":[{"start_url":"/es/","lang":"es","name":"Camping La Mola","description":"Camping La Mola"},{"start_url":"/en/","lang":"en","name":"La Mola Camping","description":"La Mola Camping"},{"start_url":"/fr/","lang":"fr","name":"Camping La Mola","description":"Camping La Mola"},{"start_url":"/nl/","lang":"nl","name":"Camping La Mola","description":"Camping La Mola"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fae06734ca90182ecee89c4dbff720a2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1380,"quality":90,"withWebp":true,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
